@import './settings';

body.math {
	background-color: $white;

	a,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: map-get($math-palette, primary);
	}

	.button {
		&.primary {
			&:not(.clear):not(.hollow) {
				background-color: map-get($math-palette, primary);
			}

			&.hollow {
				border-color: map-get($math-palette, primary);
				color: map-get($math-palette, primary);
			}
		}
	}

	.Loader {
		> div > div {
			background-color: map-get($math-palette, primary);
		}
	}
}
