@import './foundation';
@import './mixins';

body {
	min-height: 100%;

	&.purple {
		background-color: map-get($branding-palette, 'purple');
	}
}

#root {
	min-height: 100%;
}

#{text-inputs()},
a {
	&:focus,
	&:active {
		@include focus-outline('text-inputs');
	}
}

.button {
	&.primary:not(.clear):not(.hollow) {
		background-color: map-get($foundation-palette, primary);
		color: white;

		&:active,
		&:focus,
		&:hover {
			color: white;
		}
	}

	&:focus,
	&:active {
		@include focus-outline(default);
	}

	&:not(.clear) {
		&:focus,
		&:active {
			box-shadow: $input-shadow-focus;
		}
	}
}

.ReactModal__Overlay {
	position: absolute !important;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100%;
	width: 100%;
	right: auto !important;
	bottom: auto !important;
	background-color: rgba(51, 51, 51, 0.8) !important;
}

.__react_component_tooltip {
	opacity: 1 !important;
}

@import './mathTheme';
