@import './settings';

@mixin focus-outline($animation-name, $color: map-get($foundation-palette, primary), $start-opacity: 1, $end-opacity: 0, $duration: 1s) {
	@keyframes #{$animation-name} {
		from {
			box-shadow: 0 0 rem-calc(10) rgba(scale-color($color, $lightness: -15%), $start-opacity);
		}

		to {
			box-shadow: 0 0 rem-calc(10) rgba($color, $end-opacity);
		}
	}

	animation: #{$animation-name} $duration;
	outline: none;
}
