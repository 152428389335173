@import './settings';

.ForgotPassword,
.ForgotSiteCode,
.Login,
.ResetPassword,
.SignOut {
	> header {
		margin: rem-calc(40) 0 rem-calc(50);
		background-color: transparent;
		border: none;
	}

	.login-card {
		margin: rem-calc(30) auto;
		max-width: rem-calc(700);
		border-radius: calc(#{$global-radius}* 2);
	}

	.Footer {
		color: $white;

		a {
			color: $white;
			font-weight: $global-weight-bold;
			border-bottom: 1px solid $white;
			padding-bottom: 1px;

			&:hover {
				color: $white;
			}
		}
	}

	@include breakpoint(small down) {
		> header {
			margin-top: rem-calc(15);
		}
	}
}
