$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: 'fonts' !default;

$icon-download3: '\e9c7';
$icon-warning: '\ea07';
$icon-notification: '\ea08';
$icon-info: '\ea0c';
$icon-arrow-right2: '\ea3c';
$icon-arrow-left2: '\ea40';
$icon-pencil: '\e905';
$icon-cross: '\ea0f';
$icon-checkmark: '\ea10';
$icon-eye: '\e9ce';
$icon-eye-blocked: '\e9d1';
$icon-spinner11: '\e984';
