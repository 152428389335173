@import 'variables';

@font-face {
	font-family: '#{$icomoon-font-family}';
	src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?6ljuqd');
	src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?6ljuqd#iefix') format('embedded-opentype'),
		url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?6ljuqd') format('truetype'),
		url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?6ljuqd') format('woff'),
		url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?6ljuqd##{$icomoon-font-family}') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

i.icon {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: '#{$icomoon-font-family}' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-download3 {
	&:before {
		content: $icon-download3;
	}
}
.icon-warning {
	&:before {
		content: $icon-warning;
	}
}
.icon-notification {
	&:before {
		content: $icon-notification;
	}
}
.icon-info {
	&:before {
		content: $icon-info;
	}
}
.icon-arrow-right2 {
	&:before {
		content: $icon-arrow-right2;
	}
}
.icon-arrow-left2 {
	&:before {
		content: $icon-arrow-left2;
	}
}
.icon-pencil {
	&:before {
		content: $icon-pencil;
	}
}
.icon-cross {
	&:before {
		content: $icon-cross;
	}
}
.icon-checkmark {
	&:before {
		content: $icon-checkmark;
	}
}
.icon-eye {
	&:before {
		content: $icon-eye;
	}
}
.icon-eye-blocked {
	&:before {
		content: $icon-eye-blocked;
	}
}
.icon-spinner11 {
	&:before {
		content: $icon-spinner11;
	}
}
